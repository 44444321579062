
import { useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
const DeletePage = () => {

    const [formData, setFormData] = useState({});
    const [submit, setSubmit] = useState(false);
    const [otp, setOtp] = useState("");
    const [msg, setMsg] = useState("");
    const [error, setError] = useState(null);
    const [msg2, setMsg2] = useState("");
    const [error2, setError2] = useState(null);
    // Refs for input elements
    const fNameRef = useRef(null);
    const lNameRef = useRef(null);
    const emailRef = useRef(null);
    const otpRef = useRef(null);

    const handleSubmit = async (e) => {
        try {
          const res = await fetch('http://77.37.45.69:9009/api/user/deleteotp', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          const data = await res.json();
          console.log(data.success);
          setError(data.success)
          setMsg(data.message);
          console.log(error)
          if(res.ok){
            setSubmit(true);

            setOtp(data.rest.token);
          }
        } catch (error) {
          console.log('Something went wrong');
        }
        
      };

    const handleOtpSubmit = async(e) =>{
        console.log(formData)
        console.log(otp)
        setMsg("")
        if(formData.otp === otp ){
            try {
                const res = await fetch('http://77.37.45.69:9009/api/user/submitreq', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(formData),
                });
                const data = await res.json();
                setError2(data.success)
                setMsg2(data.message);
                setMsg("");
                
          setFormData({});
          fNameRef.current.value = "";
          lNameRef.current.value = "";
          emailRef.current.value = "";
          otpRef.current.value = "";
          setOtp("")

              } catch (error) {
                console.log('Something went wrong');
              }
        }
        if(formData.otp !== otp){
            setError2(false);
            setMsg2("Invalid Otp entered")
        }
        setFormData({});
    }
    return (
        <div style={{ margin: '0 35%' }}>
            <div className="container">
            <h1>Hello</h1>
            <br></br>
            <br></br>
            <h2 className='text-center'>Delete Request</h2>
            <br></br>
            <form>
                <div class="row">
                <div class="col">
                    <label for="exampleInputEmail1" class="form-label">First Name</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        aria-label="First name"
                        required
                        ref={fNameRef}
                        style={{ border: '1px solid #d3d3d3', borderRadius: '5px' }}
                        onChange={(e) =>
                            setFormData({ ...formData, fName: e.target.value })
                        }
                    >
                    </input>

                </div>
                <div class="col">
                    <label for="exampleInputEmail1" class="form-label">Last Name</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        aria-label="Last name"
                        required
                        style={{ border: '1px solid #d3d3d3', borderRadius: '5px' }}
                        ref={lNameRef}
                        onChange={(e) =>
                            setFormData({ ...formData, lName: e.target.value })
                        }
                    >
                    </input>
                </div>

                </div>

                <div class="mb-3 mt-3">
                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                    <input 
                        type="email" 
                        class="form-control" 
                        required
                        id="exampleInputEmail1" 
                        ref={emailRef}
                        aria-describedby="emailHelp"
                        style={{ border: '1px solid #d3d3d3', borderRadius: '5px' }}
                        onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                        }
                    >   
                    </input>
                    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                    
                </div>
                {/* <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">OTP</label>
                    <input type="password" class="form-control" id="exampleInputPassword1"></input>
                </div> */}
                <button type="button" class="btn btn-primary" onClick={handleSubmit} disabled={submit}>Submit</button>
            </form>
            {/* <button type="button" class="btn btn-outline-info">Info</button> */}
            </div>
            <br></br>
                {!error && <div className='text-center' id="submitHelp" class="form-text"><h6 className='text-center' style={{color: "red"}}>{msg}</h6></div>}
                {error && <div className='text-center' id="submitHelp" class="form-text"><h6 className='text-center' style={{color: "green"}}>{msg}</h6></div>}
            <br></br>
            <br></br>
            {submit && (
            <>   
            <div className="container">
                            <h2 className='text-center'>Email Id Verification</h2>
                <form>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Enter OTP</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        id="exampleInputEmail1" 
                        aria-describedby="emailHelp" 
                        style={{ border: '1px solid #d3d3d3', borderRadius: '5px' }}
                        ref={otpRef}
                        onChange={(e) =>
                            setFormData({ ...formData, otp: e.target.value })
                        }
                    >
                    </input>
                    <div id="emailHelp" class="form-text"></div>
                </div>
                <button type="button" class="btn btn-outline-info" onClick={handleOtpSubmit}>Submit</button>
                </form>
            </div>
            <br></br>
            {error2 && <div className='text-center' id="submitHelp" class="form-text"><h6 className='text-center' style={{color: "green"}}>{msg2}</h6></div>}
            {!error2 && <div className='text-center' id="submitHelp" class="form-text"><h6 className='text-center' style={{color: "red"}}>{msg2}</h6></div>}
            </>
            )}
            

        </div>
    )
}
export default DeletePage;