import React, {useEffect, useState} from 'react';
import Compostyle from '../components/compostyle.css';
import { useNavigate } from 'react-router-dom';

const ProfSignup = () => {
    const [name, setName]=useState("");
    const [email, setEmail]=useState("");
    const [mobileno, setMobno]=useState("");
    const [password, setPwd]=useState("");
    const navigate = useNavigate();

    useEffect(()=>{
        const auth = localStorage.getItem('user');
        if(auth){
            navigate('/')
        }
    })

    const [emailErr, setEmailErr]=useState(false); 
    const [phoneErr, setPhoneErr]=useState(false);

    const [error,setError]=useState(false);

    function emailhandler(e){
        let item = e.target.value;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        
        setEmail(e.target.value)
        if(item.match(emailRegex)){
            setEmailErr(false);
        } else{
            setEmailErr(true);
        }
    }

    function phonenohandler(e){
        let itemno = e.target.value;
        var phoneno = /^\d{10}$/;

        setMobno(e.target.value)
        if(itemno.match(phoneno)){
            setPhoneErr(false)
        }else {
            setPhoneErr(true)
        }
    }

    const collectData = async () => {
        console.warn(!name)
        if(!name || !email || !mobileno || !password){
            setError(true);
            return false;
        }
        
        console.warn(name,email,mobileno,password);
        let result = await fetch('https://wellnesswarriorsnp.onrender.com/api/user/register',{
            method:'POST',
            body: JSON.stringify({name,email,mobileno,password}),
            headers: {'Content-Type':'application/json'}
        });
        result = await result.json();
        console.warn(result);
        localStorage.setItem("user",JSON.stringify(result));
        if(result){
            navigate('/features')
        }   
    }
    
    return (
        <>
        <div className='container'>
            <div className='row'>
                <div className='col-4 mt-5 p-5 bg-primary'>
                    <div className='signup'>
                        <h3>Sign up</h3>
                        

                        <input className='form-control inputBox' type='text' 
                        value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter Full Name' />
                         {error && !name && <span className='invalid-text'>Invalid Name </span>}

                        <input className='form-control inputBox' type='text' 
                        value={email} onChange={(e)=>setEmail(e.target.value)/*emailhandler*/} placeholder='Enter Email address' />
                        {error && !email && <span className='invalid-text'>Invalid Email </span>}
                        
                        <input className='form-control inputBox' type='text'
                        value={mobileno} onChange={/*(e)=>setMobno(e.target.value)*/phonenohandler} placeholder='Enter Mobile Number' />
                        {error && !mobileno && <span className='invalid-text'>Empty Mobile Number</span>}
                        {mobileno && phoneErr?<span className='invalid-text'>Invalid Mobile No </span>:""}

                        <input className='form-control inputBox' type='password'
                        value={password} onChange={(e)=>setPwd(e.target.value)} placeholder='Enter Password' />
                        <div className='m-2'>
                            <button onClick={collectData} className="btn btn-outline-dark" type="submit">Sign Up{""}</button>
                        </div>
                        
                    </div>

                </div>

                <div className='col-8 nt-5 p-5 bg-info'>
                    

                </div>

               
            </div>

        </div>
        
        </>
    )

}

export default ProfSignup;