import Featuress from './Featuress.css'



const Features = () => {
    return ( 
        <>
        <div className="container mt-5 mb-3">
            <div className="row">
        <div className="container col-lg-3 col-sm-6 col-xs-6 mt-2 ">
            <div className="card">
                <img src="../images/women-in-depression.png" className="card-img-top img-fluid"alt=""/>
                <div className="card-body">
                    <h5 className="card-title text-black">Depression</h5>
                    <p className="card-text text-black">Do you feel like your sadness just wont go away, and it is hard to find a way ahead We can help.</p>
                </div>
            </div>   
        </div>

        <div className="container col-lg-3 col-sm-6 col-xs-6 mt-2">
            <div className="card">
                <img src="../images/anxiety.jpeg" className="card-img-top img-fluid" alt=""/>
                <div className="card-body">
                    <h5 className="card-title text-black">Anxiety</h5>
                    <p className="card-text text-black">Do you feel like your sadness just wont go away, and it is hard to find a way ahead We can help.</p>
                </div>
            </div>   
        </div>

        <div className="container col-lg-3 col-sm-6 col-xs-6 mt-2">
            <div className="card">
                <img src="../images/ocd.avif" className="card-img-top img-fluid" alt=""/>
                <div className="card-body">
                    <h5 className="card-title text-black">Obsessive Compulsive Disorder(OCD)</h5>
                    <p className="card-text text-black">Are unwanted thoughts, ideas or sensations making you anxious ? </p>
                </div>
            </div>   
        </div>

        <div className="container col-lg-3 col-sm-6 col-xs-6 mt-2">
            <div className="card">
                <img src="../images/bipolar-img.jpg" className="card-img-top img-fluid" alt=""/>
                <div className="card-body">
                    <h5 className="card-title text-black">Bipolar Disorder</h5>
                    <p className="card-text text-black">Do you struggle with periods of intense mood swings ranging from depressive lows to manic highs?</p>
                </div>
            </div>   
        </div>
        </div>
        </div>
        </>
      
    )
}

export default Features;