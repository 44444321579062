import './App.css';
import Header from './Headers';
import ResNavbar from './ResNavbar';
import Features from './Features';

import AboutUs from './components/AboutUs';
import BsNavbar from './BsNavbar';
import Stepss from './Stepss';
import FooterSection from './FooterSection';
import HomePage from './components/HomePage';

import PrivateComponent from './components/PrivateComponent';
import ProfilePage from './components/ProfilePage';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProfSignup from './components/ProfSignup';
import ServicePage from './components/ServicesPage';
import Psychologists from './components/Psychologists';
import Psy from './Psy';
import DeletePage from './components/DeletePage';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
  <>
    <BrowserRouter>
    {/* <ResNavbar /> */}
    <BsNavbar/>
    <Routes>
      <Route element={<PrivateComponent/>}>
        <Route path='/features' element={<Features/>}/>
        <Route path='/profile' element={<ProfilePage/>}/>
      </Route>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/steps' element={<Stepss />}/>
        <Route path='/aboutus' element={<AboutUs/>}/>
        <Route path='/services' element={<ServicePage/>}/>
        <Route path='/delete' element={<DeletePage/>}/>
        <Route path='/policy' element={<PrivacyPolicy/>}/>
        <Route path='/signup' element={<ProfSignup/>}/>
        <Route path='/psychologists' element={<Psychologists/>}/>
        <Route path='/psy' element={<Psy/>}/>
    </Routes>
    {/*<Header /> 
    <Features />
  */}
    
    </BrowserRouter>
    <FooterSection />
  </>
    
  );
}


export default App;

