import Features from "../Features";
import Headers from "../Headers";
import Stepss from "../Stepss";

const HomePage = () => {
    return (
        <>
        {/*<div className="container">
            <div className="row">
                <div className="col-12 mt-5 p-5 bg-info">

                </div>
                <div>

                </div>
            </div>
    </div>*/}
    <Headers/>
    <Features/>
    {/*<Stepss/>*/}
        </>
    )
}

export default HomePage;