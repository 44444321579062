const Psychologists = () =>{
    return(
        <>
        <div className="container">
            <div className="row">
                <div className="col-3 p-5 mt-5 bg-info"></div>
                <div className="col-3 p-5 mt-5 bg-dark"></div>
                <div className="col-3 p-5 mt-5 bg-primary"></div>
                <div className="col-3 p-5 mt-5 bg-secondary"></div>
            </div>

            <div>
                <div className="column">
                    <div className="col-3 bg-primary">ABC</div>
                    <div className="col-3 bg-dark">DEF</div>
                    <div>GHI</div>
                    <div>JKL</div>
                    <div>MNO</div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Psychologists;