const Stepss = () => {
    return(
        <>
        <div className="container mt-5">
            <div>
                <div>
                    <p className="text-center font-monospace">HOW IT WORKS?</p>
                    <p className="text-center">Start your journey in 4 EASY steps</p>
                    <div className="row">
                    
                    <div className="col-3"  >
                        
                        <img src="../images/ww_android_app.webp" width='200' className="img-fluid"/>
                        
                        {/*<h5 className="text-center">Download App</h5>*/}
                        <p></p>
                        <i class="bi bi-arrow-right"></i>
                    </div>
                    
                    <div className="col-3" >
                        <img src="./images/select-candidate.avif" className="img-fluid" />
                        <h5 className="text-center">Select Psychologist</h5>
                        <p></p>
                    </div>

                    <div className="col-3" >
                        <img src="./images/select-candidate.avif" className="img-fluid"/>
                        <h5 className="text-center">Book Appointment</h5>
                        <p></p>
                    </div>

                    <div className="col-3" >
                        <img src="./images/select-candidate.avif" className="img-fluid"/>
                        <h5 className="text-center">Start Therapy</h5>
                        <p></p>
                    </div>
                    </div>
                </div>                
            </div>

        </div>

        </>
    )
}

export default Stepss;