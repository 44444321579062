const AboutUs = () => {

    return(
        <>
        <div className="container">
            <div className="row">

                <div className="col-6 mt-5 bg-primary">

                </div>

                <div className="col-6 mt-5 p-1 bg-info">

                </div>

                <div className="col-lg-8 col-sm-12 mt-1 p-1 text-center">About Us
                <h3>Meet the Directors</h3><br/>
                <p>Wellness warriors is co-founded by <b>Dr Kratika</b> in December 2023, The keen interest of co-founders is to create 
                    a service model where psychologist services can be availed on a click. In cases where a patient needs/require 
                    medicine or immediate medical attention will be referred to Psychiatrist. Keeping sessions online will help people share their
                     worries without visiting the clinic. </p>
                    <p><b>Wellness Warriors</b> is a healthcare division of <b>Business Innovation Blaze</b></p>
                         <p>Wellness Warriors apply tested pshychological modals and methods for the resolution of problems shared by the clients. Wellness Warriors is open to colloborate with prestegious health care institutions and organisations for betterment of society </p></div>

                <div className="col-lg-4 mt-5 p-1">
                    <img src={require(`../img/healthcare-division.png`)} width='100%' alt="about-us" />
                </div>
                <div className="col-12 p-5 "></div>
                

            </div>
            
        </div>
        </>
    )
}

export default AboutUs;