const ProfilePage = () => {
    return(
        <>
        <div className="container">
            <div className="row">
                <div className="col-4 mt-5 p-5 bg-info">
                    <div>Profile Image</div>

                </div>
                <div className="col-4 bg-primary">

                </div>
                <div className="col-4 bg-dark">

                </div>
            </div>
        </div>
        </>
    )
}

export default ProfilePage;